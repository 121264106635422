<template>
  <el-dialog
    :visible.sync="visible"
    :title="!dataForm.id ? $t('add') : $t('update')"
    :close-on-click-modal="false"
    :before-close="resetData"
    :close-on-press-escape="false"
    width="80%"
  >
    <el-card>
      <div slot="header">
        <span>{{ $t('package.packagesText') }}</span>
      </div>
      <el-form
        :model="dataForm"
        :rules="dataRule"
        ref="dataForm"
        @keyup.enter.native="dataFormSubmitHandle()"
        label-width="150px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item prop="packageName" :label="$t('package.packageName')">
              <el-input
                v-model="dataForm.packageName"
                :placeholder="$t('package.packageName')"
                :disabled="disabled"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="packageCode" :label="$t('package.packageCode')">
              <el-input
                v-model="dataForm.packageCode"
                :placeholder="$t('package.packageCode')"
                :disabled="true"
                v-if="dataForm.id"
              ></el-input>
              <el-input
                v-else
                v-model="dataForm.packageCode"
                :placeholder="$t('package.packageCode')"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item prop="status" :label="$t('package.status')">
              <el-select
                v-model="dataForm.status"
                :placeholder="$t('package.status')"
                style="width: 100%"
              >
                <el-option
                  v-for="item in statusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('package.packageDate')">
              <el-date-picker
                v-model="packageDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                style="width: 100%"
                :disabled="disabled"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="dataForm.startDate && dataForm.endDate">
          <el-col :span="12">
            <el-form-item
              prop="activateType"
              :label="$t('package.activateType')"
            >
              <el-select
                v-model="dataForm.activateType"
                :placeholder="$t('package.activateType')"
                @change="selectChange('activateType')"
                style="width: 100%"
                :disabled="disabled"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="dataForm.activateType == '01'" :span="12">
            <el-form-item
              prop="activatePeriod"
              :label="$t('package.activatePeriod')"
            >
              <el-input
                v-model="dataForm.activatePeriod"
                :placeholder="$t('package.activatePeriod')"
                style="width: 80%"
                :disabled="disabled"
              ></el-input>
              天内
            </el-form-item>
          </el-col>
          <el-col v-else :span="12">
            <el-form-item :label="$t('package.activatePeriod')">
              <el-date-picker
                v-model="activateDate"
                type="daterange"
                :picker-options="packageOptions"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                style="width: 80%"
                :disabled="disabled"
              >
              </el-date-picker>
              之间
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="dataForm.startDate && dataForm.endDate">
          <el-col :span="12">
            <el-form-item prop="usefulType" :label="$t('package.usefulType')">
              <el-select
                v-model="dataForm.usefulType"
                :placeholder="$t('package.usefulType')"
                style="width: 100%"
                :disabled="
                  dataForm.healthRightList.length > 0 ||
                  dataForm.activateCount > 0
                "
                @change="selectChange('usefulType')"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="dataForm.usefulType == '01'" :span="12">
            <el-form-item
              prop="usefulPeriod"
              :label="$t('package.usefulPeriod')"
            >
              <el-input
                v-model="dataForm.usefulPeriod"
                :placeholder="$t('package.usefulPeriod')"
                style="width: 80%"
                :disabled="dataForm.healthRightList.length > 0"
              ></el-input>
              天内
            </el-form-item>
          </el-col>
          <el-col v-else :span="12">
            <el-form-item :label="$t('package.usefulPeriod')">
              <el-date-picker
                v-model="usefulPeriodDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                style="width: 80%"
                :disabled="dataForm.healthRightList.length > 0"
              >
              </el-date-picker>
              之间
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item prop="packageDesc" :label="$t('package.packageDesc')">
              <el-input
                v-model="dataForm.packageDesc"
                :placeholder="$t('package.packageDesc')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <!-- :label="$t('package.productId')" -->
            <el-form-item prop="appCode" label="合作方">
              <el-select
                v-model="dataForm.appCode"
                placeholder="合作方"
                label="appDesc"
                :clearable="true"
                style="width: 100%"
                :disabled="dis"
              >
                <el-option
                  v-for="item in listByScope"
                  :key="item.appCode"
                  :label="item.appDesc"
                  :value="item.appCode"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="productId" label="合作方产品">
              <el-select
                v-model="dataForm.productId"
                :placeholder="$t('package.productId')"
                label="name"
                :clearable="true"
                style="width: 100%"
                :disabled="dis"
              >
                <el-option
                  v-for="item in productList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item prop="smsCode" :label="$t('package.smsCode')">
              <el-select
                v-model="dataForm.smsCode"
                :placeholder="$t('package.smsCode')"
                label="smsName"
                :clearable="true"
                style="width: 100%"
                :disabled="disabled"
              >
                <el-option
                  v-for="item in smsList"
                  :key="item.smsCode"
                  :label="item.smsName"
                  :value="item.smsCode"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="activateCount" label="激活量">
              <el-input
                v-model="dataForm.activateCount"
                style="width: 100%"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item prop="changeRightUserFlag" label="使用人可否更换">
              <el-radio
                v-model="dataForm.changeRightUserFlag"
                :label="1"
                :disabled="disabled"
                >是</el-radio
              >
              <el-radio
                v-model="dataForm.changeRightUserFlag"
                :disabled="disabled"
                :label="0"
                >否</el-radio
              >
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="totalRightUserCount" label="使用人数">
              <el-input-number
                v-model="dataForm.totalRightUserCount"
                :min="1"
                :max="10"
                placeholder="使用人数"
                :disabled="disabled"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="dataForm.totalRightUserCount > 1">
          <el-col :span="12">
            <el-form-item
              prop="addRightUserValidDays"
              label="卡激活后可增加权益人有效时长(天)"
            >
              <el-input-number
                v-model="dataForm.addRightUserValidDays"
                :min="1"
                :max="1000"
                placeholder="可增加权益人有效时长(天)"
                :disabled="disabled"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item prop="cardFaceSelect" label="卡面选择">
              <el-radio
                v-model="dataForm.cardFaceSelect"
                :label="-1"
                :disabled="disabled"
                >默认卡面</el-radio
              >
              <el-radio
                v-model="dataForm.cardFaceSelect"
                :label="1"
                :disabled="disabled"
                >自定义卡面</el-radio
              >
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="dataForm.cardFaceSelect == 1">
            <el-form-item prop="defineCardFaceUrl" label="卡面图片">
              <el-upload
                :action="action"
                :show-file-list="false"
                :on-success="cardSuccess"
                accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
                :disabled="disabled"
              >
                <img
                  v-if="dataForm.defineCardFaceUrl"
                  :src="dataForm.defineCardFaceUrl"
                  class="avatar"
                />

                <div v-else>
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              prop="packageServiceAgreementUrl"
              label="产品服务协议url"
            >
              <el-input
                v-model="dataForm.packageServiceAgreementUrl"
                placeholder="产品服务协议url"
                :disabled="disabled"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="点击上传产品faq" prop="packageFAQUrl">
              <el-upload
                :action="action"
                :show-file-list="false"
                :on-success="bannerSuccess"
                accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
                :disabled="disabled"
              >
                <el-input
                  v-if="dataForm.packageFAQUrl"
                  :value="dataForm.packageFAQUrl"
                  disabled
                />

                <div v-else>
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="showChooseMedic">
          <el-form-item prop="drugsStoreCode" label="药房权益">
            <div v-for="(item, index) in medicRoomList" :key="index">
              <el-radio
                v-model="dataForm.drugsStoreCode"
                :label="item.drugsStoreCode"
                :disabled="disabled"
                >{{ item.drugsStoreName }}</el-radio
              >
              <el-table
                :data="medicRoomList[index].couponList"
                style="min-width: 80%"
                header-row-class-name="tableHead"
              >
                <el-table-column label="优惠券" align="center" width="250">
                  <template slot-scope="scope">
                    <el-input-number
                      input-border-hidden
                      v-model="
                        medicRoomList[index].couponList[scope.$index].quantity
                      "
                      :disabled="
                        dataForm.drugsStoreCode !== item.drugsStoreCode ||
                        disabled
                      "
                    ></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column
                  label="折扣（0-1）例如：0.9=九折"
                  align="center"
                  width="250"
                >
                  <template slot-scope="scope">
                    <el-input-number
                      input-border-hidden
                      v-model="
                        medicRoomList[index].couponList[scope.$index]
                          .discountRate
                      "
                      :disabled="
                        dataForm.drugsStoreCode !== item.drugsStoreCode ||
                        disabled
                      "
                      :step="0.01"
                      :max="1"
                    ></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column
                  label="免邮订单额度"
                  align="center"
                  width="250"
                >
                  <template slot-scope="scope">
                    <el-input
                      input-border-hidden
                      v-model="
                        medicRoomList[index].couponList[scope.$index]
                          .freeShippingPrice
                      "
                      :disabled="
                        dataForm.drugsStoreCode !== item.drugsStoreCode ||
                        disabled
                      "
                    >
                      <template slot="append">元</template>
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="250">
                  <template slot-scope="scope">
                    <el-button @click="deleteThisLine(index, scope.$index)"
                      >删除</el-button
                    >
                    <el-button
                      @click="addMoreLine(index, scope.$index)"
                      v-if="scope.$index === item.couponList.length - 1"
                      type="primary"
                      >增加</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>
        </el-row>
      </el-form>
    </el-card>
    <el-card>
      <div slot="header">
        <span>{{ $t('package.rightsText') }}</span>
      </div>
      <el-table :data="dataForm.healthRightList" border style="min-width: 100%">
        <el-table-column
          align="center"
          width="50"
          :render-header="rightsTableHeader"
          fixed="left"
        >
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="删除" placement="right">
              <i
                class="el-icon-delete"
                @click="deleteRightsList(scope.row.rightId)"
              ></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('rights.rightName')"
          align="center"
          width="250"
        >
          <template slot-scope="scope">
            <el-select
              v-model="dataForm.healthRightList[scope.$index].rightId"
              :placeholder="$t('rights.rightName')"
              label="rightName"
              filterable
              @change="changeRight(scope.$index)"
              :disabled="disabled"
            >
              <el-option
                v-for="item in rightsList"
                :key="item.id"
                :label="item.rightName"
                :value="item.id"
                :disabled="item.status === 0 || dataForm.activateCount > 0"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('rights.rightNameAlias')"
          align="center"
          width="250"
        >
          <template slot-scope="scope">
            <el-input
              input-border-hidden
              v-model="dataForm.healthRightList[scope.$index].aliasRightName"
            ></el-input>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('rights.aliasRightDescAlias')"
          align="center"
          width="250"
        >
          <template slot-scope="scope">
            <el-input
              input-border-hidden
              v-model="dataForm.healthRightList[scope.$index].aliasRightDesc"
            ></el-input>
          </template>
        </el-table-column>

        <el-table-column :label="$t('rights.waitPeriod')" align="center">
          <template slot-scope="scope">
            <el-input
              input-border-hidden
              v-model="dataForm.healthRightList[scope.$index].waitPeriod"
              :disabled="disabled"
              :placeholder="$t('rights.waitPeriod')"
              type="number"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('rights.availableCount')"
          align="center"
          :render-header="rightsCountHeader"
        >
          <template slot-scope="scope">
            <el-input
              input-border-hidden
              v-model="dataForm.healthRightList[scope.$index].availableCount"
              :disabled="disabled"
              :placeholder="$t('rights.availableCount')"
              type="number"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('rights.usefulPeriod')"
          align="center"
          v-if="dataForm.usefulType == '01'"
        >
          <template slot-scope="scope">
            <el-input
              input-border-hidden
              v-model="dataForm.healthRightList[scope.$index].usefulPeriod"
              :disabled="disabled"
              :placeholder="$t('rights.usefulPeriod')"
              type="number"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('rights.usefulStartEndDate')"
          align="center"
          v-else
        >
          <template slot-scope="scope">
            <el-date-picker
              v-model="dataForm.healthRightList[scope.$index].usefulPeriodDate"
              :picker-options="rightsOptions"
              @change="rightsDateChange(scope.$index)"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 100%"
              :disabled="disabled"
            >
            </el-date-picker>
          </template>
        </el-table-column>

        <!-- 
          <el-table-column
          :label="$t('handle')"
          fixed="right"
          header-align="center"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="deleteRightsList(scope.row.id)"
              >{{ $t('delete') }}</el-button
            >
          </template>
        </el-table-column>
         -->
      </el-table>
    </el-card>
    <template slot="footer">
      <el-button @click="visibleDismiss">{{ $t('cancel') }}</el-button>
      <el-button
        type="primary"
        :loading="loading"
        @click="dataFormSubmitHandle()"
        >{{ $t('confirm') }}</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce';
import mixinViewModule from '@/mixins/view-module';
let _minPackageTime = null;
let _maxPackageTime = null;
let _minRightsTime = null;
let _maxRightsTime = null;
// 6 ONLINE_QUERY
// 21 ONLINE_HEALTH_CONSULTATION
// 22 VIDEO_HEALTH_CONSULTATION
// 51 INNOVATE_VIDEO_HEALTH_CONSULTATION
// 56 INNOVATE_ONLINE_QUERY
const medicType = [
  'ONLINE_QUERY',
  'ONLINE_HEALTH_CONSULTATIO',
  'VIDEO_HEALTH_CONSULTATION',
  'INNOVATE_VIDEO_HEALTH_CONSULTATION',
  'INNOVATE_ONLINE_QUERY',
];
export default {
  mixins: [mixinViewModule],
  data() {
    return {
      initType: '1',
      listByScope: [], //合作方
      externalListByScope: [], //合作方产品
      showChooseMedic: false,
      medicRoomList: [], // 药房列表
      mixinViewModuleOptions: {
        createdIsNeed: false,
      },
      dataForm: {
        id: '',
        packageName: '',
        packageDesc: '',
        packageCode: '',
        status: '',
        activateType: '01',
        usefulType: '01',
        activateEndDate: '',
        activateStartDate: '',
        usefulEndDate: '',
        usefulStartDate: '',
        activatePeriod: '',
        usefulPeriod: '',
        channelId: '',
        healthRightList: [],
        activateCount: 0,
        price: '',
        productId: '',
        smsCode: '',
        startDate: '',
        endDate: '',
        changeRightUserFlag: 0, // 使用人可否更换	0表示不可以 1表示可以
        totalRightUserCount: 0, // 使用人数
        addRightUserValidDays: '', // 卡激活后可增加权益人有效时长(天)	使用人数>1时必传
        cardFaceSelect: -1, // 整型 卡面选择 -1表示默认卡面 1表示自定义卡面
        defineCardFaceUrl: '', //可选 自定义卡面url 卡面选择自定义时必传
        packageServiceAgreementUrl: '', // 字符型 可选 产品服务协议url
        packageFAQUrl: '', // 可选 产品faq url
        drugsStoreCode: '', // 药房编码
        couponList: [], // 优惠券
        appCode: '',
      },
      options: [
        {
          value: '01',
          label: '时间点',
        },
        {
          value: '02',
          label: '时间段',
        },
      ],
      statusOptions: [
        {
          value: 0,
          label: '禁用',
        },
        {
          value: 1,
          label: '正常',
        },
      ],
      activateDate: [],
      usefulPeriodDate: [],
      packageDate: [],
      dataList: [],
      rightsItem: {},
      channelList: [],
      productList: [],
      smsList: [],
      packageOptions: {
        disabledDate(time) {
          // onPick后触发
          // 该方法会轮询当3个月内的每一个日期，返回false表示该日期禁选
          if (_minPackageTime && _maxPackageTime) {
            return (
              time.getTime() <= _minPackageTime ||
              time.getTime() > _maxPackageTime
            );
          }
        },
      },
      rightsOptions: {
        disabledDate(time) {
          // onPick后触发
          // 该方法会轮询当3个月内的每一个日期，返回false表示该日期禁选
          if (_minRightsTime && _maxRightsTime) {
            return (
              time.getTime() <= _minRightsTime ||
              time.getTime() > _maxRightsTime
            );
          }
        },
      },
      rightsList: [],
      visible: false,
      loading: false,
      action: `${
        window.SITE_CONFIG['apiURL']
      }/sys/oss/upload?token=${sessionStorage.getItem('token')}`,
    };
  },
  computed: {
    dis() {
      return this.disabled || this.dataForm.activateCount > 0;
    },
    dataRule() {
      return {
        packageName: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
          { min: 3, message: '长度须大于3位', trigger: 'blur' },
        ],
        packageCode: [
          {
            required: false,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        status: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        startDate: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        endDate: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        changeRightUserFlag: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        totalRightUserCount: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        defineCardFaceUrl: [
          {
            required: this.dataForm.cardFaceSelect == 1 ? true : false,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
      };
    },
    disabled() {
      return this.activateCount > 0 ? true : false;
    },
  },
  watch: {
    packageDate: {
      handler: function (val) {
        if (val) {
          this.dataForm.startDate = val[0];
          this.dataForm.endDate = val[1];
        } else {
          this.dataForm.startDate = '';
          this.dataForm.endDate = '';
        }
      },
    },
    activateDate: {
      handler: function (val) {
        if (val) {
          this.dataForm.activateStartDate = val[0];
          this.dataForm.activateEndDate = val[1];
        } else {
          this.dataForm.activateStartDate = '';
          this.dataForm.activateEndDate = '';
        }
      },
    },
    usefulPeriodDate: {
      handler: function (val) {
        if (val) {
          this.dataForm.usefulStartDate = val[0];
          this.dataForm.usefulEndDate = val[1];
        } else {
          this.dataForm.usefulStartDate = '';
          this.dataForm.usefulEndDate = '';
        }
      },
    },
    'dataForm.appCode': {
      handler: function (val) {
        if (this.initType == '2') this.dataForm.productId = '';
        this.getProductList();
      },
    },
    'dataForm.startDate': {
      handler: function (val) {
        _minPackageTime = new Date(val) - 1;
      },
    },
    'dataForm.endDate': {
      handler: function (val) {
        _maxPackageTime = new Date(val);
      },
    },
    'dataForm.usefulStartDate': {
      handler: function (val) {
        _minRightsTime = new Date(val) - 1;
      },
    },
    'dataForm.usefulEndDate': {
      handler: function (val) {
        _maxRightsTime = new Date(val);
      },
    },
    'dataForm.healthRightList': {
      handler: function (val) {
        const res = val.find((i) => {
          return medicType.includes(i.rightCode);
        });
        if (res) {
          if (this.showChooseMedic) return;
        }
        this.medicRoomList = [];
        this.showChooseMedic = res ? true : false;
      },
      deep: true,
    },
  },
  methods: {
    deleteThisLine(index, ind) {
      this.medicRoomList[index].couponList.length > 1
        ? this.medicRoomList[index].couponList.splice(ind, 1)
        : false;
    },
    addMoreLine(index, ind) {
      const modalArr = this.medicRoomList;
      modalArr[index].couponList.push({
        quantity: 0,
        discountRate: 0,
        freeShippingPrice: 0,
      });
      this.medicRoomList = modalArr;
    },
    bannerSuccess(res) {
      this.$set(this.dataForm, 'packageFAQUrl', this.initImg(res));
    },
    cardSuccess(res) {
      this.$set(this.dataForm, 'defineCardFaceUrl', this.initImg(res));
    },
    initImg(res) {
      const { code = 99, data = {}, msg = '网络异常' } = res;
      if (code != 0) {
        this.$message.error(msg);
        return false;
      }
      return data.src;
    },
    visibleDismiss() {
      this.visible = false;
      this.resetData();
    },
    rightsCountHeader(h, { column }) {
      return h('div', [
        h('span', column.label),
        h(
          'el-tooltip',
          {
            props: {
              effect: 'dark',
              content: '-1:代表无限次',
              placement: 'right',
            },
          },
          [
            h('i', {
              class: 'el-icon-warning-outline red',
            }),
          ]
        ),
      ]);
    },
    rightsTableHeader(h, { column, $index }) {
      return h('i', {
        class: {
          'el-icon-plus': true,
        },
        on: {
          click: this.addRightsList,
        },
      });
    },
    rightsDateChange(val) {
      console.log(val);
      this.dataForm.healthRightList[val].usefulStartDate =
        this.dataForm.healthRightList[val].usefulPeriodDate[0];
      this.dataForm.healthRightList[val].usefulEndDate =
        this.dataForm.healthRightList[val].usefulPeriodDate[1];
    },
    addRightsList() {
      if (
        (this.dataForm.usefulType == '01' &&
          !this.dataForm.usefulPeriod &&
          this.dataForm.usefulPeriod <= 0) ||
        (this.dataForm.usefulType == '02' &&
          !this.dataForm.usefulStartDate &&
          !this.dataForm.usefulEndDate)
      ) {
        this.$message.error('使用有效期不能为空');
        return false;
      }
      let rights = {};
      if (this.dataForm.usefulType == '01') {
        rights = {
          rightId: '',
          rightName: '',
          rightCode: '',
          waitPeriod: '',
          availableCount: '',
          usefulPeriod: this.dataForm.usefulPeriod,
          aliasRightDesc: '',
          aliasRightName: '',
        };
      } else {
        rights = {
          rightId: '',
          rightName: '',
          rightCode: '',
          waitPeriod: '',
          availableCount: '',
          usefulStartDate: this.dataForm.usefulStartDate,
          usefulEndDate: this.dataForm.usefulEndDate,
          usefulPeriodDate: [
            this.dataForm.usefulStartDate,
            this.dataForm.usefulEndDate,
          ],
          aliasRightDesc: '',
          aliasRightName: '',
        };
      }
      this.dataForm.healthRightList.push(rights);
    },
    resetData() {
      Object.assign(this.$data, this.$options.data());
    },
    init() {
      this.initType = '1';
      this.visible = true;

      Promise.all([
        this.getRightList(),

        this.getSmsList(),
        this.getListByScope(),
      ]).then(() => {
        if (this.dataForm.id) {
          this.getInfo();
        }
      });
    },
    getListByScope() {
      this.$http
        .get('/sysapp/listByScope')
        .then((res) => {
          console.log(res, 'listByScope');
          if (res.data.code === 0) {
            this.listByScope = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //
    getRightList() {
      this.$http
        .get('/api/health/right/list')
        .then((res) => {
          console.log(res);
          if (res.data.code === 0) {
            this.rightsList = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectChange(val) {
      if (val === 'activateType') {
        this.dataForm.activateStartDate = '';
        this.dataForm.activateEndDate = '';
        this.dataForm.activatePeriod = '';
        this.activateDate = [];
      } else if (val === 'usefulType') {
        this.dataForm.usefulStartDate = '';
        this.dataForm.usefulEndDate = '';
        this.dataForm.usefulPeriod = '';
        this.usefulPeriodDate = [];
      }
    },
    deleteRightsList(id) {
      let inx = this.dataForm.healthRightList.findIndex((item) => {
        return id == item.rightId;
      });
      // console.log(this.dataForm.healthRightList, id, inx);
      this.dataForm.healthRightList.splice(inx, 1);
    },
    // 获取信息
    getInfo() {
      this.$http
        .get(`/health/package/info?id=${this.dataForm.id}`)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          res.data.healthRightList.forEach((element) => {
            element.usefulPeriodDate = [
              element.usefulStartDate,
              element.usefulEndDate,
            ];
          });
          this.dataForm = {
            ...this.dataForm,
            ...res.data,
          };

          // if (this.dataForm.couponList?.length) {
          this.getMedicRoom();
          // }
          this.packageDate = [res.data.startDate, res.data.endDate];
          if (res.data.activateType !== '01') {
            this.activateDate = [
              res.data.activateStartDate,
              res.data.activateEndDate,
            ];
          }
          if (res.data.usefulType !== '01') {
            this.usefulPeriodDate = [
              res.data.usefulStartDate,
              res.data.usefulEndDate,
            ];
          }
        })
        .catch(() => {});
    },
    getProductList() {
      this.$http
        .get('/product/external/listByScope', {
          params: {
            appCode: this.dataForm.appCode,
          },
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.productList = res.data.data;
          }
          this.initType = '2';
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSmsList() {
      this.$http
        .get('/sys/sms/list')
        .then((res) => {
          console.log(res);
          if (res.data.code === 0) {
            this.smsList = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeRight(index) {
      console.log('触发');
      let item = this.rightsList.find((val) => {
        return val.id == this.dataForm.healthRightList[index].rightId;
      });
      this.dataForm.healthRightList[index].rightCode = item.rightCode;
      this.dataForm.healthRightList[index].rightName = item.rightName;
      this.dataForm.healthRightList[index].aliasRightName = item.rightName;
      this.dataForm.healthRightList[index].aliasRightDesc = item.rightDesc;

      if (medicType.includes(item.rightCode) && !this.medicRoomList.length) {
        this.getMedicRoom();
      }
    },
    async getMedicRoom() {
      const { data } = await this.$http.get('trtc/drugsstore/list');
      this.medicRoomList = data.data;
      // if (this.dataForm.couponList?.length) {
      const dataModel = this.dataForm.couponList.length
        ? this.dataForm.couponList
        : [
            {
              quantity: 0,
              discountRate: 0,
              freeShippingPrice: 0,
            },
          ];
      this.medicRoomList.map((i) => {
        const flag = i.drugsStoreCode === this.dataForm.drugsStoreCode;
        console.log(flag, 'flag', this.dataForm.drugsStoreCode);
        i.couponList = flag
          ? dataModel
          : [
              {
                quantity: 0,
                discountRate: 0,
                freeShippingPrice: 0,
              },
            ];
      });
      // }
      // this.medicRoomList.map((i) => {
      //     i.couponList = [
      //         {
      //             quantity: 0,
      //             discountRate: 0,
      //             freeShippingPrice: 0,
      //         },
      //     ];
      // });
    },
    repeatRight() {
      let arry = JSON.parse(JSON.stringify(this.dataForm.healthRightList));
      let a = [];
      let b = [];
      for (let i = 0; i < arry.length; i++) {
        let ainx = a.findIndex((item) => {
          return item.rightId == arry[i].rightId;
        });
        if (ainx > -1) {
          b.push(arry[i]);
        } else {
          a.push(arry[i]);
        }
      }
      return b.length > 0 ? true : false;
    },
    // 表单提交
    dataFormSubmitHandle: debounce(
      function () {
        this.$refs['dataForm'].validate((valid) => {
          if (!valid) {
            return false;
          }
          if (
            this.dataForm.activateType !== '01' &&
            (!this.dataForm.activateEndDate || !this.dataForm.activateStartDate)
          ) {
            this.$message.error(
              '激活有效期类型为时间段时激活有效期开始结束时间不能为空!'
            );
            return false;
          }
          if (
            this.dataForm.usefulType !== '01' &&
            (!this.dataForm.usefulEndDate || !this.dataForm.usefulStartDate)
          ) {
            this.$message.error(
              '使用有效期类型为时间段时激活有效期开始结束时间不能为空!'
            );
            return false;
          }

          if (this.dataForm.healthRightList.length > 0) {
            for (let i = 0; i < this.dataForm.healthRightList.length; i++) {
              let item = this.dataForm.healthRightList[i];
              if (
                item.usefulPeriod &&
                (Number(item.usefulPeriod) > this.dataForm.usefulPeriod ||
                  Number(item.usefulPeriod) <= 0)
              ) {
                this.$message.error(
                  '权益有效使用期必须大于0且小于等于权益包使用有效!'
                );
                return false;
              }
              if (!item.rightName) {
                this.$message.error('权益名称不能为空!');
                return false;
              }
              if (!item.aliasRightName) {
                this.$message.error('权益别名不能为空!');
                return false;
              }
              if (!item.aliasRightDesc) {
                this.$message.error('描述别名不能为空!');
                return false;
              }
              if (!String(item.waitPeriod)) {
                this.$message.error('权益等待期不能为空!');
                return false;
              } else if (!!String(item.waitPeriod) && item.waitPeriod < 0) {
                this.$message.error('权益等待期必须为大于等于0的正整数!');
                return false;
              }
              if (!item.availableCount) {
                this.$message.error('权益可使用的次数不能为空!');
                return false;
              }
              if (
                item.availableCount &&
                Number(item.availableCount) <= 0 &&
                Number(item.availableCount) !== -1
              ) {
                this.$message.error('权益可使用的次数必须大于0或者-1!');
                return false;
              }
            }
          } else {
            this.$message.error('权益列表不能为空!');
            return false;
          }
          if (this.showChooseMedic && !this.dataForm.drugsStoreCode) {
            this.$message.error('请选择药房!');
            return false;
          }
          const fakeList = this.medicRoomList.filter((i) => {
            return i.drugsStoreCode === this.dataForm.drugsStoreCode;
          })[0]?.couponList;
          if (fakeList && fakeList[0]?.quantity >= 1) {
            this.dataForm.couponList = fakeList;
          }
          this.loading = true;
          this.$http
            .post(
              !this.dataForm.id
                ? '/health/package/save'
                : '/health/package/update',
              this.dataForm
            )
            .then(({ data: res }) => {
              this.loading = false;
              if (res.code !== 0) {
                return this.$message.error(res.msg);
              }
              this.$message({
                message: this.$t('prompt.success'),
                type: 'success',
                duration: 500,
                onClose: () => {
                  this.visible = false;
                  this.$emit('refreshDataList');
                },
              });
              this.resetData();
            })
            .catch(() => {
              this.loading = false;
            });
        });
      },
      1000,
      { leading: true, trailing: false }
    ),
  },
};
</script>
<style>
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border: 1px solid #dcdfe6;
}
.flexBox {
  display: flex;
}
.blockInput {
  display: block;
  width: auto;
}
.tableHead {
  height: 20px;
}
</style>
